// src/utils/loadAssets.js
import { config } from "../config";
const loadCSS = (href) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
};

const loadJS = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
};

const loadAssets = (isAdmin) => {
    const baseUrl = config.frontEndBaseUrl;

    const adminAssets = [
        // `${baseUrl}adminAssets/css/animate.min.css`,
        // `${baseUrl}adminAssets/css/animation.css`,
        `${baseUrl}adminAssets/css/bootstrap.css`,
        `${baseUrl}adminAssets/css/bootstrap-select.min.css`,
        `${baseUrl}adminAssets/css/style.css`,
        `${baseUrl}adminAssets/css/login.css`,
        // `${baseUrl}adminAssets/css/adminAddProductsForSocial.css`,
        `${baseUrl}adminAssets/font/fonts.css`,
        `${baseUrl}adminAssets/icon/style.css`,
        `${baseUrl}adminAssets/images/logo_trans.png`,
    ];


    const userAssets = [
        `${baseUrl}userAssets/css/plugins/css/bootstrap.css`,
        `${baseUrl}userAssets/css/styles.css`,
        `${baseUrl}userAssets/css/colors.css`,
        `${baseUrl}userAssets/css/CustomCSS/Society.css`,
        `${baseUrl}userAssets/css/CustomCSS/navs_footer_style.css`,
        `${baseUrl}userAssets/css/CustomCSS/Home.css`,
        `${baseUrl}userAssets/css/CustomCSS/BlockWings.css`,
        `${baseUrl}userAssets/css/CustomCSS/Block-Details.css`,
        `${baseUrl}userAssets/fonts/fonts.css`,
        `${baseUrl}userAssets/css/plugins/animation.css`,
        `${baseUrl}userAssets/css/plugins/date-picker.css`,
        `${baseUrl}userAssets/css/plugins/dropzone.css`,
        `${baseUrl}userAssets/css/plugins/font-awesome.css`,
        `${baseUrl}userAssets/css/plugins/icofont.css`,
        `${baseUrl}userAssets/css/plugins/light-box.css`,
        `${baseUrl}userAssets/css/plugins/magnific-popup.css`,
        `${baseUrl}userAssets/css/plugins/select2.css`,
        `${baseUrl}userAssets/css/plugins/slick-theme.css`,
        `${baseUrl}userAssets/css/plugins/slick.css`,

    ];



    const commonAssets = [
        `https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css`,
        `${baseUrl}userAssets/js/jquery.min.js`,
        `${baseUrl}userAssets/js/bootstrap.min.js`,
        // `${baseUrl}userAssets/js/lightbox.js`,
        // `${baseUrl}userAssets/js/slick.js`,
        // `${baseUrl}userAssets/js/slider-bg.js`,
        // `${baseUrl}userAssets/js/map.js`,
        // `${baseUrl}userAssets/js/custom.js`,
        // `${baseUrl}userAssets/js/CustomJs/block_betails.js`,
        // `${baseUrl}js/bootstrap-select.min.js`,
        
    ];

    const assetsToLoad = isAdmin ? adminAssets.concat(commonAssets) : userAssets.concat(commonAssets)

    // console.log(assetsToLoad)

    assetsToLoad.forEach((asset) => {
        if (asset.endsWith('.css')) {
            loadCSS(asset);
        } else if (asset.endsWith('.js')) {
            loadJS(asset);
        }
    });
};

export default loadAssets;
