import "./App.css";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from "react-router-dom";
import { AdminSidebarContextProvider } from './context/adminSidebarContext';
import Loading from './Loading/Loading';
import loadAssetsForVendor from './utils/loadAssetsForVendor';
import loadAssets from './utils/loadAssets';


// Lazy loaded components for users===================
const UserNavbar = lazy(() => import('./users/component/Navbar/Navbar'));
const UserFooter = lazy(() => import('./users/component/Footer/Footer'));


const Login = lazy(() => import('./users/pages/login/Login'));
const SignInDetails = lazy(() => import('./users/pages/SignInDetails/SignInDetails'))
const OtpVerification = lazy(() => import('./users/pages/Otp_verification/OtpVerification'))
const Signup = lazy(() => import('./users/pages/Signup/Signup'));

const Home = lazy(() => import("./users/pages/Home/Home"));
const Socities = lazy(() => import("./users/pages/Socities/Socities"));
const Cities = lazy(() => import("./users/pages/Cities/Cities"));
const Society = lazy(() => import("./users/pages/Society/Society"));
const BlockWings = lazy(() => import("./users/pages/BlockWings/BlockWings"));
const BlockDetails = lazy(() => import("./users/pages/BlockDetails/BlockDetails"));
const State = lazy(() => import("./users/pages/State/State"));


// Lazy loaded components for admin=======================
const AdminLogin = lazy(() => import('./admin/Pages/AdminLogin/AdminLogin'));
const AdminNavbar = lazy(() => import('./admin/components/Navbar/Navbar'));
const AdminFooter = lazy(() => import('./admin/components/Footer/Footer'));
const Dashboard = lazy(() => import('./admin/Pages/Dashboard/Dashboard'));
const AdminComponents = lazy(() => import('./admin/Pages/AdminComponents/AdminComponents'));
const AddStore = lazy(() => import('./admin/Pages/AddStore/AddStore'));
const StoreList = lazy(() => import('./admin/Pages/StoreList/StoreList'));
const CategoryList = lazy(() => import('./admin/Pages/CategoryList/CategoryList'));
const NewCategory = lazy(() => import('./admin/Pages/NewCategory/NewCategory'));
const SubCategory = lazy(() => import('./admin/Pages/SubCategory/SubCategory'));
const SubCategoryList = lazy(() => import('./admin/Pages/SubCategorylist/SubCategoryList'));
const AddBanners = lazy(() => import('./admin/Pages/AddBanners/AddBanners'));
const AllBanners = lazy(() => import('./admin/Pages/AllBanners/AllBanners'));
const CategoryUpdate = lazy(() => import('./admin/Pages/CategoryUpdate/CategoryUpdate'));
const SubCategoryUpdateForm = lazy(() => import('./admin/Pages/SubCategoryUpdate/SubCategoryUpdate'))
const Notifications = lazy(() => import('./admin/Pages/Notifications/Notifications'))
const BannersImages = lazy(() => import('./admin/Pages/BannersImages/BannersImages'))
const AdminSetting = lazy(() => import('./admin/Pages/AdminSetting/AdminSetting'))
const BannerUpdate = lazy(() => import('./admin/Pages/BannerUpdate/BannerUpdate'))
const AddBannerIamge = lazy(() => import('./admin/Pages/AddBannerIamge/AddBannerIamge'))
const BannersImagesUpdate = lazy(() => import('./admin/Pages/BannersImagesUpdate/BannersImagesUpdate'))
const AddCategoryBanner = lazy(() => import('./admin/Pages/AddCategoryBanner/AddCategoryBanner'))
const CategoryBannerImages = lazy(() => import('./admin/Pages/CategoryBannerImages/CategoryBannerImages'))
const UpdateCategoryBanner = lazy(() => import('./admin/Pages/UpdateCategoryBanner/UpdateCategoryBanner'))
const StoreImages = lazy(() => import('./admin/Pages/StoreImages/StoreImages'))
const AddCoverImage = lazy(() => import('./admin/Pages/StoreImages/CoverImages/AddCoverImage/AddCoverImage'))
const AddStoreBannerImage = lazy(() => import('./admin/Pages/StoreImages/BannerImages/AddStoreBannerImage/AddStoreBannerImage'))
const AddStoreGallaryImage = lazy(() => import('./admin/Pages/StoreImages/GallaryImages/AddGallaryImage/AddGallaryImage'))
const AddLogo = lazy(() => import('./admin/Pages/StoreImages/LogoImage/AddLogo/AddLogo'))
const StoreProducts = lazy(() => import('./admin/Pages/StoreProducts/StoreProducts'))
const AddProduct = lazy(() => import('./admin/Pages/StoreProducts/AddProduct/AddProduct'))
const UpdateProduct = lazy(() => import('./admin/Pages/StoreProducts/UpdateProduct/UpdateProduct'))
const UpdateStoreImage = lazy(() => import('./admin/Pages/StoreImages/UpdateStoreImage/UpdateStoreImage'))
const ProductImages = lazy(() => import('./admin/Pages/StoreProducts/ProductImages/ProductImages'))
const AddProductImage = lazy(() => import('./admin/Pages/StoreProducts/AddProductImage/AddProductImage'))
const UpdateProductImage = lazy(() => import('./admin/Pages/StoreProducts/UpdateProductImage/UpdateProductImage'))
const Orders = lazy(() => import('./admin/Pages/Orders/Orders'))
const Queries = lazy(() => import('./admin/Pages/Queries/Queries'))
const Vendors = lazy(() => import('./admin/Pages/Vendor/Vendors'))
const VendorsAdd = lazy(() => import('./admin/Pages/VendorsAdd/VendorsAdd'))
const StoreComments = lazy(() => import('./admin/Pages/StoreComments/StoreComments'))
const Leads = lazy(() => import('./admin/Pages/Leads/Leads'))
const UserLeads = lazy(() => import('./admin/Pages/UserLeads/UserLeads'))
const UpdateStore = lazy(() => import('./admin/Pages/UpdateStore/UpdateStore'));
const AmenitiesList = lazy(()=>import('./admin/Pages/Amenities/List/AmenitiesList'))
const AmenitiesAdd = lazy(()=>import('./admin/Pages/Amenities/Add/AmenitiesAdd')) 
const LoadingScreen = lazy(() => import('./users/component/LoadingScreen/LoadingScreen') )


/// Lazy loaded components for vendor=============
// const VendorLogin = lazy(() => import('./vendor/pages/VendorLogin/VendorLogin'));
// const VendorNavbar = lazy(() => import('./vendor/components/Navbar/Navbar'));
// const VendorFooter = lazy(() => import('./vendor/components/Footer/Footer'));
// const VendorDashboard = lazy(() => import('./vendor/pages/Dashboard/Dashboard'));
// const VendorProductsList = lazy(() => import('./vendor/pages/StoreProducts/StoreProducts'))
// const UpdateProductVendor = lazy(() => import('./vendor/pages/StoreProducts/UpdateProduct/UpdateProductVendor'))
// const ProductImagesVendor = lazy(()=>import('./vendor/pages/StoreProducts/ProductImages/ProductImagesVendor'))
// const AddProductImageVendor = lazy(()=>import('./vendor/pages/StoreProducts/AddProductImage/AddProductImageVendor'))
// const UpdateProductImageVendor = lazy(()=>import('./vendor/pages/StoreProducts/UpdateProductImage/UpdateProductImageVendor'))
// const VendorAddProduct = lazy(() => import('./vendor/pages/StoreProducts/AddProduct/AddProduct'))
// const VendorSetting = lazy(() => import('./vendor/pages/AdminSetting/AdminSetting'))
// const VendorNotifications = lazy(() => import('./vendor/pages/Notifications/Notifications'))
// const VendorCustomerCare = lazy(() => import('./vendor/pages/CustomerCare/CustomerCare'))
// const OtpVerificationVendor = lazy(() => import('./vendor/pages/Otp_verification/OtpVerificationVendor'))
// const UpdateVendorStore = lazy(() => import('./vendor/pages/UpdateStore/UpdateAdminStore'))
// const StoreImagesVendor = lazy(() => import('./vendor/pages/StoreImages/StoreImagesVendor'))
// const AddCoverImageVendor = lazy(() => import('./vendor/pages/StoreImages/CoverImages/AddCoverImage/AddCoverImageVendor'))
// const AddStoreBannerImageVendor = lazy(() => import('./vendor/pages/StoreImages/BannerImages/AddStoreBannerImage/AddStoreBannerImageVendor'))
// const AddStoreGallaryImageVendor = lazy(() => import('./vendor/pages/StoreImages/GallaryImages/AddGallaryImage/AddGallaryImageVendor'))
// const AddLogoVendor = lazy(() => import('./vendor/pages/StoreImages/LogoImage/AddLogo/AddLogoVendor'))
// const UpdateStoreImageVendor = lazy(() => import('./vendor/pages/StoreImages/UpdateStoreImage/UpdateStoreImageVendor'))
// const OrdersVendor = lazy(()=> import('./vendor/pages/Orders/OrdersVendor'))
// const QueriesVendors = lazy(()=> import('./vendor/pages/Queries/QueriesVendor'))
// const StoreCommentsVendor = lazy(()=> import('./vendor/pages/StoreComments/StoreCommentsVendor'))

function App() {

	// return(
	// 	<LoadingScreen />
	// )

	useEffect(() => {
		const isAdmin = window.location.href.includes('admin');
		loadAssets(isAdmin);
	}, []);
	useEffect(() => {
		const isVendor = window.location.href.includes('vendor');
		loadAssetsForVendor(isVendor);
	}, []);


	// const [feedbackOn, setFeedbackOn] = useState(false);

	// let currentUser = localStorage.getItem('userAuthToken');
	let currentUser = true;
	let currentAdmin = localStorage.getItem('adminAuthToken');
	// let currentVendor = localStorage.getItem('vendorAuthToken');

	// Users layout start
	const ProtectedRouteUsers = ({ children }) => {
		if (currentUser) {
			return children;
		}
		return <Navigate to="/sign-in" />;
	};

	const LayoutUsers = () => {
		const location = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [location.pathname]);

		return (
			<>
				<Suspense fallback={<LoadingScreen />}>
					<Outlet />
					<UserFooter />
				</Suspense>
			</>
		);
	};

	// Admin layout start
	const ProtectedRouteAdmin = ({ children }) => {
		if (currentAdmin) {
			return children;
		}
		return <Navigate to="/admin/login" />;
	};

	const LayoutAdmin = () => {
		const location = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [location.pathname]);

		return (
			<>
				<Suspense fallback={<Loading />}>
					<AdminNavbar />
					<Outlet />
					<AdminFooter />
				</Suspense>
			</>
		);
	};
	// Admin layout end

	// Vendor layout start
	// const ProtectedRouteVendor = ({ children }) => {
	// 	if (currentVendor) {
	// 		return children;
	// 	}
	// 	return <Navigate to="/vendor/login" />;
	// };
	// const LayoutVendor = () => {
	// 	const location = useLocation();
	// 	useEffect(() => {
	// 		window.scrollTo(0, 0);
	// 	}, [location.pathname]);
	// 	return (
	// 		<>
	// 			<Suspense fallback={<Loading />}>
	// 				<VendorNavbar />
	// 				<Outlet />
	// 				<VendorFooter />
	// 			</Suspense>
	// 		</>
	// 	);
	// };
	// Vendor layout end

	// ----All Routes---
	const router = createBrowserRouter([
		// ----usersRoutes-----
		{
			path: "/",
			element: (
				<ProtectedRouteUsers>
					<LayoutUsers />
				</ProtectedRouteUsers>
			),
			children: [
				{
					path: "/",
					element: <>
						<UserNavbar />
						<Home />
					</>,
				},
				{
					path: "/cities/:uid",
					element: <Cities />,
				},
				{
					path: "/cities",
					element: <Cities />,
				},
				{
					path: "/socities/:uid",
					element: <Socities />,
				},
				{
					path: "/socities",
					element: <Socities />,
				},
				{
					path: "/society/:uid",
					element: <>
						<UserNavbar />
						<Society />
					</>,
				},
				{
					path: "/block-wings",
					element: <>
						<UserNavbar />
						<BlockWings />
					</>,
				},
				{
					path: "/block-details/:uid",
					element: <>
						<UserNavbar />
						<BlockDetails />
					</>,
				},
				{
					path: "/states",
					element: <State />,
				},
			],
		},
		{
			path: '/sign-in',
			element: (
				<Suspense fallback={<Loading />}>
					<Login />
				</Suspense>
			),
		},
		{
			path: '/signup',
			element: (
				<Suspense fallback={<Loading />}>
					<Signup />
				</Suspense>
			),
		},
		{
			path: 'sign-in/verify-otp/:uid',
			element: (
				<Suspense fallback={<Loading />}>
					<OtpVerification />
				</Suspense>
			),
		},
		{
			path: 'sign-in/details/:uid',
			element: (
				<Suspense fallback={<Loading />}>
					<SignInDetails />
				</Suspense>
			),
		},

		// ------admin routes--------
		{
			path: '/admin',
			element: (
				<ProtectedRouteAdmin>
					<LayoutAdmin />
				</ProtectedRouteAdmin>
			),
			children: [
				{
					path: '/admin',
					element: <Dashboard />,
				},
				{
					path: '/admin/society/add',
					element: <AddStore />,
				},
				{
					path: '/admin/society/update/:uid',
					element: <UpdateStore />,
				},
				{
					path: '/admin/society/list',
					element: <StoreList />,
				},
				{
					path: '/admin/banner/add',
					element: <AddBanners />,
				},
				{
					path: '/admin/banner/list',
					element: <AllBanners />,
				},
				{
					path: '/admin/state/banner/image/:uid',
					element: <CategoryBannerImages />,
				},
				{
					path: '/admin/banner/image/add/:uid',
					element: <AddBannerIamge />,
				},
				{
					path: '/admin/society/comments/:uid',
					element: <StoreComments />
				},
				{
					path: '/admin/banner/image/update/:uid/',
					element: <BannersImagesUpdate />,
				},
				{
					path: '/admin/product/update/:uid/',
					element: <UpdateProduct />,
				},
				{
					path: '/admin/society/image/update/:uid/',
					element: <UpdateStoreImage />,
				},
				{
					path: '/admin/product/images/:uid',
					element: <ProductImages />
				},
				{
					path: '/admin/society/amenities/:uid',
					element: <AmenitiesList />
				},
				{
					path: '/admin/society/amenities/add/:uid',
					element: <AmenitiesAdd />
				},
				{
					path: '/admin/society/product/image/add/:uid',
					element: <AddProductImage />,
				},
				{
					path: '/admin/product/image/update/:uid',
					element: <UpdateProductImage />,
				},
				{
					path: '/admin/banner/update/:uid',
					element: <BannerUpdate />,
				},
				{
					path: '/admin/banner/image/:uid',
					element: <BannersImages />,
				},
				{
					path: '/admin/state/list',
					element: <CategoryList />,
				},
				{
					path: '/admin/components',
					element: <AdminComponents />,
				},
				{
					path: '/admin/state/add',
					element: <NewCategory />,
				},
				{
					path: '/admin/society/images/:uid',
					element: <StoreImages />
				},
				{
					path: '/admin/society/product/add/:uid',
					element: <AddProduct />
				},
				{
					path: '/admin/society/products/:uid',
					element: <StoreProducts />
				},
				{
					path: '/admin/society/cover/add/:uid',
					element: <AddCoverImage />
				},
				{
					path: '/admin/society/logo/add/:uid',
					element: <AddLogo />
				},
				{
					path: '/admin/society/banner/add/:uid',
					element: <AddStoreBannerImage />
				},
				{
					path: '/admin/society/gallary/add/:uid',
					element: <AddStoreGallaryImage />
				},
				{
					path: '/admin/society/orders',
					element: <Orders />
				},
				{
					path: '/admin/queries',
					element: <Queries />
				},
				{
					path: '/admin/vendors',
					element: <Vendors />
				},
				{
					path: '/admin/vendors/add',
					element: <VendorsAdd />
				},
				{
					path: '/admin/state/update/:uid',
					element: <CategoryUpdate />,
				},
				{
					path: '/admin/city/update/:uid',
					element: <SubCategoryUpdateForm />,
				},
				{
					path: '/admin/city/add/:uid',
					element: <SubCategory />,
				},
				{
					path: '/admin/city/add/',
					element: <SubCategory />,
				},
				{
					path: '/admin/city/list',
					element: <SubCategoryList />,
				},
				{
					path: '/admin/city/list/:uid',
					element: <SubCategoryList />,
				},
				{
					path: '/admin/notifications',
					element: <Notifications />,
				},
				{
					path: '/admin/setting',
					element: <AdminSetting />,
				},
				{
					path: '/admin/state/banner/add',
					element: <AddCategoryBanner />
				},
				{
					path: '/admin/state/banner/add/:uid',
					element: <AddCategoryBanner />
				},
				{
					path: '/admin/state/banner/image/update/:uid',
					element: <UpdateCategoryBanner />
				},
				{
					path: '/admin/leads/:uid',
					element: <Leads />
				},
				{
					path: '/admin/user/leads/:uid',
					element: <UserLeads />
				}
			],
		},
		{
			path: '/admin/login',
			element: (
				<Suspense fallback={<Loading />}>
					<AdminLogin />
				</Suspense>
			),
		},

		// ------vendor routes--------
		// {
		// 	path: '/vendor',
		// 	element: (
		// 		<ProtectedRouteVendor>
		// 			<LayoutVendor />
		// 		</ProtectedRouteVendor>
		// 	),
		// 	children: [
		// 		{
		// 			path: '/vendor',
		// 			element: <VendorDashboard />,
		// 		},
		// 		{
		// 			path: '/vendor/society/products/:uid',
		// 			element: <VendorProductsList />
		// 		},
		// 		{
		// 			path: '/vendor/society/product/add/:uid',
		// 			element: <VendorAddProduct />
		// 		},
		// 		{
		// 			path: '/vendor/notifications',
		// 			element: <VendorNotifications />
		// 		},
		// 		{
		// 			path: '/vendor/customer-Care',
		// 			element: <VendorCustomerCare />
		// 		},
		// 		{
		// 			path: '/vendor/setting',
		// 			element: <VendorSetting />,
		// 		},
		// 		{
		// 			path: '/vendor/society/update/:uid',
		// 			element: <UpdateVendorStore />,
		// 		},
		// 		{
		// 			path: '/vendor/society/images/:uid',
		// 			element: <StoreImagesVendor />,
		// 		},
		// 		{
		// 			path: '/vendor/society/cover/add/:uid',
		// 			element: <AddCoverImageVendor />
		// 		},
		// 		{
		// 			path: '/vendor/society/logo/add/:uid',
		// 			element: <AddLogoVendor />
		// 		},
		// 		{
		// 			path: '/vendor/society/banner/add/:uid',
		// 			element: <AddStoreBannerImageVendor />
		// 		},
		// 		{
		// 			path: '/vendor/society/gallary/add/:uid',
		// 			element: <AddStoreGallaryImageVendor />
		// 		},
		// 		{
		// 			path: '/vendor/society/image/update/:uid',
		// 			element: <UpdateStoreImageVendor />
		// 		},
		// 		{
		// 			path: '/vendor/orders/:uid',
		// 			element: <OrdersVendor />
		// 		},
		// 		{
		// 			path: '/vendor/enquiries/:uid',
		// 			element: <QueriesVendors />
		// 		},
		// 		{
		// 			path: '/vendor/comments/:uid',
		// 			element: <StoreCommentsVendor />
		// 		},
		// 		{
		// 			path: '/vendor/product/images/:uid',
		// 			element: <ProductImagesVendor />
		// 		},
		// 		{
		// 			path: '/vendor/product/update/:uid',
		// 			element: <UpdateProductVendor />
		// 		},
		// 		{
		// 			path: '/vendor/society/product/image/add/:uid',
		// 			element: <AddProductImageVendor />
		// 		},
		// 		{
		// 			path : '/vendor/product/image/update/:uid',
		// 			element: <UpdateProductImageVendor />
		// 		}

		// 	],
		// },
		// {
		// 	path: '/vendor/login',
		// 	element: (
		// 		<Suspense fallback={<Loading />}>
		// 			<VendorLogin />
		// 		</Suspense>
		// 	),
		// },
		// {
		// 	path: '/vendor/verify-otp/:uid',
		// 	element: (
		// 		<Suspense fallback={<Loading />}>
		// 			<OtpVerificationVendor />
		// 		</Suspense>
		// 	),
		// 	},
	]);

	return (
		<>
			<AdminSidebarContextProvider>
				<RouterProvider router={router} />
			</AdminSidebarContextProvider>
		</>
	);
}

export default App;
